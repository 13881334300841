body {
    margin: 0;
    font-family: sans-serif;
}

html,
body,
#root,
.App {
    height: 100%;
}

.rgt-cell:not(.rgt-row-edit):not(.rgt-row-not-selectable) {
    cursor: pointer;
}

.rgt-row-selected {
    background: #f3f6f9;
}

.rgt-row-hover {
    background: #f7f7f7;
}
/* styles.css (or your preferred CSS file) */
/* styles.css (or your preferred CSS file) */
.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
}

.image-item {
    text-align: center; /* Center align images */
}

.image-item img {
    max-width: 100%; /* Limit image width to its container */
    max-height: 100%; /* Limit image height to its container */
}
/* Add this to your CSS file */
.vertical-divider {
    border-right: 1px solid #ccc; /* Adjust the color and thickness as needed */
    height: 100%; /* Make the divider cover the full height of the images section */
}

/* Add this to your CSS file */
.image-item {
    margin-bottom: 20px; /* Adjust the margin as needed for the desired spacing */
}
.image-item img {
    border: 2px solid white; /* Adjust the border width and style as needed */
}

.image-group h4 {
    font-size: 24px; /* Adjust the font size as needed */
    text-align: center; /* Center the text horizontally */
    margin-top: 10px; /* Add margin to center the text vertically */
    font-weight: normal; /* Remove bold styling */
}