/* In your CSS file or inline styles */
.progress-bar-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px; /* Set the desired height of the progress bar */
    background-color: #f0f0f0; /* Set the background color of the progress bar container */
    z-index: 9999; /* Set a high z-index to make sure it's displayed on top */
}

.progress-bar {
    height: 100%;
    background-color: #007bff; /* Set the color of the progress bar */
}

/* Style the progress bar green when upload is completed */
.progress-bar-container.completed .progress-bar {
    background-color: #00cc00;
}
